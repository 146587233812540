<template>
  <div class="mb-4">
    <div class="container-fluid mt-4 mx-2 px-3">
      <DashboardFilters></DashboardFilters>
    </div>
  </div>
</template>

<script>
import DashboardFilters from './DashboardFilters.vue'

export default {
  name: 'DashboardHeader',
  components: {
    DashboardFilters,
  },
}
</script>

