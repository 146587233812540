<template>
  <MainContent>
    <DashboardView />
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import DashboardView from '@/components/Modules/Dashboard/DashboardView'
import { bootIntercom, trackEvent } from '@/lib/analytics'

export default {
  name: 'DashboardPage',
  components: {
    MainContent,
    DashboardView,
  },
  async mounted() {
    bootIntercom(this.$intercom)
    trackEvent(this.$intercom, 'Dashboard')
  },
}
</script>
