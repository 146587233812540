<template>
  <div>
    <div :key="activeCompany._id" v-if="activeCompany">
      <OvationSurveyModal ref="ovationSurveyModal"></OvationSurveyModal>
      <DashboardHeader />
      <DashboardBody v-if="initialized" :key="selectRefreshKey" />
    </div>
  </div>
</template>

<script>
import OvationSurveyModal from '../../../components/Modals/OvationSurveyModal'
import DashboardHeader from './DashboardHeader'
import DashboardBody from './DashboardBody.vue'
import { createNamespacedHelpers } from 'vuex'

const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')
const DashboardModule = createNamespacedHelpers('dashboard')
const LocationModule = createNamespacedHelpers('location')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'DashboardView',
  components: {
    OvationSurveyModal,
    DashboardHeader,
    DashboardBody,
  },
  data: () => ({
    initialized: false,
  }),
  computed: {
    ...CompanyModule.mapGetters(['activeCompany', 'companies']),
    ...DashboardModule.mapGetters(['selectRefreshKey']),
    ...UserModule.mapGetters(['showUserSurvey']),
  },
  watch: {
    activeCompany: {
      handler(val, oldVal) {
        this.initialized = false
        this.init()
      },
    },
  },
  methods: {
    ...DashboardModule.mapActions(['initializeFilters']),
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    ...GroupModule.mapActions(['fetchGroups']),
    async init() {
      await this.getUserLocationsByActiveCompany()
      await this.initializeFilters()
      await this.fetchGroups({
        memberType: 'location',
        companyIds: this.companies.map((c) => c._id),
      })
      this.initialized = true
    },
  },
  async mounted() {
    // Logic to Show Ovation Survey Popup
    if (this.showUserSurvey) {
      setTimeout(() => {
        this.$root.$emit('bv::show::modal', 'ovationSurveyModal')
      }, 5000)
    }

    this.init()
  },
}
</script>
