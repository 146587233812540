<template>
  <div class="container-fluid align-items-middle">
    <!-- survey section -->
    <div>
      <h1 style="font-size: 20px" class="ml-2">Surveys</h1>
    </div>
    <div>
      <!-- row 1 -->
      <div class="row mb-3">
        <div class="col-12 col-lg-6 col-xl">
          <DashboardWidget :widgetId="4" />
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <DashboardWidget :widgetId="3" />
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <DashboardWidget :widgetId="2" />
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <DashboardWidget :widgetId="5" />
        </div>
        <div class="col-12 col-lg-6 col-xl">
          <DashboardWidget :widgetId="11" />
        </div>
      </div>

      <!-- row 2-->
      <div class="row mb-3">
        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
          <DashboardWidget :widgetId="6" />
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
          <DashboardWidget :widgetId="10" />
        </div>
      </div>

      <!-- row 3 end -->
      <div class="row">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
          <DashboardWidget :widgetId="20" />
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
          <DashboardWidget :widgetId="19" />
        </div>
      </div>
    </div>

    <!-- review section -->
    <div>
      <h1 style="font-size: 20px" class="ml-2 mt-5">Online Reviews</h1>
    </div>

    <!-- row 1 -->
    <div class="row mb-3">
      <div class="col-12 col-lg-6 col-xl">
        <DashboardWidget :widgetId="15" />
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <DashboardWidget :widgetId="16" />
      </div>
      <div class="col-12 col-lg-6 col-xl">
        <DashboardWidget :widgetId="12" />
      </div>
    </div>

    <!-- row 2 start -->
    <div class="row mb-3">
      <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
        <DashboardWidget :widgetId="18" />
      </div>
      <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
        <DashboardWidget :widgetId="17" />
      </div>
    </div>
  </div>
</template>

<script>
import DashboardWidget from './Widget/DashboardWidget'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'DashboardBody',
  components: {
    DashboardWidget,
  },
  data: () => ({
    firstRowIds: [2, 3, 4, 11, 12, 5],
  }),
  mounted() {
    this.fetchTrends()
  },
  computed: {
    ...DashboardModule.mapGetters(['selectWidgetIds']),
  },
  methods: {
    ...DashboardModule.mapActions(['fetchTrends']),
  },
}
</script>
