<template>
  <div>
    <div class="d-flex flex-wrap">
      <!-- date -->
      <div class="dropdown d-inline-block mr-4 mb-2" style="min-width: 230px">
        <h6 class="header-pretitle mt-3">Date Range</h6>
        <DatePicker
          @update="dateUpdate"
          :minDate="selectOldestCompanyStartDate(filteredCompanies)"
          :key="selectOldestCompanyStartDate(filteredCompanies)"
        />
      </div>

      <!-- company -->
      <div class="dropdown d-inline-block mr-4 mb-2">
        <h6 class="header-pretitle mt-3">Company</h6>
        <CompanyFilter
          @update="companyUpdate"
          :wideMode="true"
          :companies="selectCompanies"
          :activeCompany="activeCompany"
        />
      </div>

      <!-- location -->
      <div class="dropdown d-inline-block mr-4 mb-2">
        <h6 class="header-pretitle mt-3">Location</h6>
        <GroupingFilter
          :nonGroupMembers="selectDashboardLocations(filteredCompanies)"
          nonGroupMemberLabel="Locations"
          memberType="location"
          @update="groupingUpdate"
          :init="{
            nonGroupMemberIds: this.filteredLocations,
            groupIds: this.filteredGroups,
          }"
          :key="groupRefreshKey"
          :companies="filteredCompanies"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import DatePicker from '@/components/Modules/Filters/DatePicker'
import CompanyFilter from '@/components/Modules/Filters/CompanyFilter'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')
const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'DashboardFilters',
  components: {
    GroupingFilter,
    DatePicker,
    CompanyFilter,
  },
  data: () => ({
    filteredDate: null,
    filteredCompanies: [],
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: [],
  }),
  computed: {
    ...LocationModule.mapGetters(['selectDashboardLocations']),
    ...CompanyModule.mapGetters([
      'activeCompany',
      'selectCompanies',
      'selectOldestCompanyStartDate',
    ]),
    ...GroupModule.mapGetters(['selectGroups']),
    groupRefreshKey() {
      return (
        (this.activeCompany?._id || 'NO_COMPANY') +
        (this.filteredCompanies?.join('') || '') +
        (this.selectDashboardLocations(this.filteredCompanies)?.length || 0) +
        this.selectGroups.map((g) => g._id).join('')
      )
    },
  },
  watch: {
    selectActiveCompanyIds() {
      this.filteredDate = null
      this.filteredCompanies = []
      this.filteredLocations = []
    },
  },
  methods: {
    ...DashboardModule.mapActions(['applyFilters']),
    ...LocationModule.mapActions(['fetchDashboardLocations']),

    dateUpdate(val) {
      this.filteredDate = val
      this.formatAndApplyFilters()
    },
    async companyUpdate(val) {
      this.filteredCompanies = val
      await this.fetchDashboardLocations({ companyIds: this.filteredCompanies })
      this.formatAndApplyFilters()
    },
    groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null
      this.formatAndApplyFilters()
    },
    async formatAndApplyFilters() {
      let locationsToFilter = [
        ...new Set([...(this.filteredLocations || []), ...(this.filteredGroupMembers || [])]),
      ]
      this.applyFilters({
        companies:
          this.filteredCompanies && this.filteredCompanies.length ? this.filteredCompanies : null,
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : null,
        dateRange:
          this.filteredDate && Object.keys(this.filteredDate).length ? this.filteredDate : null,
      })
    },
  },
}
</script>
